<template>
  <div id="medals" class="mt-4">
    <a href="/sales"><b-icon icon="chevron-left"></b-icon>Retour</a>
    <Datepicker
        @selected="clickDatePicker"
        id="period-flux-month"
        :format="formatDatepicker"
        :minimumView="'year'"
        :maximumView="'year'"
        :language="datepicker.language"
        :value="datepicker.defaultDate"
        input-class="text-center"
        :disabledDates="datepicker.disabledDates"
        placeholder="Choisir une période"
        class="mt-1 mr-2 text-center text-lg-right"
    />
    <div v-if="currentMedals.length > 0">
      <div  v-for="(medal, index) in currentMedals" :key="index" class="mt-3">
        <div class="mb-2">
          <div class="font-weight-bold">{{ formatMonthYear(medal.date) }}</div>
        </div>
        <b-icon :class="ranks[medal.rank]" font-scale="1.5" icon="trophy-fill"></b-icon>{{ ranks[medal.rank] }} pour <strong>{{ types[medal.type] }}</strong> dans la catégorie {{ medal.category.name }}
      </div>
    </div>
    <div v-else>
      Aucune(s) médaille(s) pour cette période
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import {fr} from "vuejs-datepicker/dist/locale";
import moment from "moment";

export default {
  computed: {
    ...mapState(['CenterModule']),
    ...mapState(['PodiumModule']),
    ...mapState(['UserModule']),
  },
  components: {
    Datepicker: () => import('vuejs-datepicker'),
  },
  data() {
    return {
      medals: [],
      ranks: {
        1: "Or",
        2: "Argent",
        3: 'Bronze'
      },
      types: {
        "monthlyYield": "le chiffre d’affaire / m² mensuel",
        "monthlyYieldEvolution": "l'évolution du chiffre d’affaires / m² mensuel",
        "rollingYield": "le chiffre d’affaires / m² sur 12 mois",
        "rollingYieldEvolution": "l'évolution du chiffre d’affaires / m² sur 12 mois"
      },
      datepicker: {
        language: fr,
        defaultDate: new Date(),
        disabledDates: {
          to: null,
          from: new Date(new Date()),
        },
      },
      currentMedals: []
    }
  },
  created() {
    this.fetchMedals({user: this.$store.getters.getCurrentUser.id})
  },

  methods: {
    moment() {
      return moment
    },
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- DATEPICKER -------------------*/
    /**
     * Event click datePicker date
     *
     * @param value
     */
    clickDatePicker(value) {
      let momentObj = moment(new Date(value));
       let date = momentObj.format('YYYY');

       this.currentMedals = [];

       this.medals.forEach(medal => {
         if(moment(medal.date).format('YYYY') === date){
           this.currentMedals.push(medal);
         }
       })
    },
    /**
     * Format date for datepicker
     */
    formatDatepicker(date) {
      let dateObj = new Date(date)
      return this.capitalizeText(moment(dateObj).format('yyyy'));
    },
    formatMonthYear(date) {
      let dateObj = new Date(date)
      return this.capitalizeText(moment(dateObj).format('MMMM yyyy'));
    },
    /*----------------- MEDALS -------------------*/
    fetchMedals(searchParams) {
      this.$store.dispatch('fetchUserMedals', {...searchParams})
          .then(() => {
              this.medals = Object.values(JSON.parse(JSON.stringify(this.$store.getters.getMedals))).filter(medal => {
                return medal.rank <= 3
            });
            let momentObj = moment(new Date());
            let date = momentObj.format('YYYY');

            this.currentMedals = [];

            this.medals.forEach(medal => {
              if(moment(medal.date).format('YYYY') === date){
                this.currentMedals.push(medal);
              }
            })
          })
    },
    formatDisplayDate(date) {
      let d = new Date(date);
      return moment(d).format
    },
    capitalizeText(initialText) {
      return initialText.charAt(0).toUpperCase() + initialText.slice(1);
    }
  }
};
</script>

<style scoped>

</style>
