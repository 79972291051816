import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// BS
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// SWEET ALERT
import VueSweetalert2 from 'vue-sweetalert2'
// LOADER CSS
import 'vue-loading-overlay/dist/vue-loading.css'
// SWEET ALERT CSS
import 'sweetalert2/dist/sweetalert2.min.css'
// APEX CHARTS
import VueApexCharts from 'vue-apexcharts'

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// Sweet alert
Vue.use(VueSweetalert2)
// Apex charts
Vue.use(VueApexCharts)

// Moment
const moment = require('moment')
require('moment/locale/fr')

Vue.use(require('vue-moment'), {
  moment
})

// CSS
require('../assets/scss/common/main.scss')

// Plugin
import plugin from './plugin/plugin'
Vue.use(plugin)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");



