import AxiosService from '@/services/AxiosService'

export default {
  /**
   * Get user by id
   *
   * @param userId
   * @returns {*}
   */
  getUser(userId) {
    return AxiosService.callApi().get('/users/' + userId)
  },
  /**
   * Edit User
   *
   * @param user
   * @returns {*}
   */
  edit(user) {
    return AxiosService.callApi().put('/users/' + user.id, user)
  },
}
