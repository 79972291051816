import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// MODULE
import ChannelModule from '@/store/modules/ChannelModule'
import AuthModule from '@/store/modules/AuthModule'
import CenterModule from '@/store/modules/CenterModule'
import UserModule from '@/store/modules/UserModule'
import SaleModule from '@/store/modules/SaleModule'
import PodiumModule from "@/store/modules/PodiumModule";
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    ChannelModule,
    AuthModule,
    CenterModule,
    UserModule,
    SaleModule,
    PodiumModule
  }
})
