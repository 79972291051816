import PublicService from "@/services/PublicService"

export default {
  state: {
    channel: [],
  },
  mutations: {
    SET_CHANNEL(state, channel) {
      state.channel = channel
    }
  },
  actions: {
    /**
     * Fetch Channel
     *
     * @param commit
     * @param data
     */
    fetchChannel({ commit }, data) {
      return PublicService.getChannel(data)
        .then(response => {
          commit('SET_CHANNEL', response.data)

          return Promise.resolve(response.data)
        })
        .catch(error => {
          console.log('There was an error on get channel: ', error)
        })
    },
  },
  getters: {
    /**
     * Get Channel
     * @param state
     * @returns {null}
     */
    getChannel(state) {
      return state.channel
    },
  }
}
