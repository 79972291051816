import PodiumService from "@/services/PodiumService";

export default {
    state: {
        medals: [],
        shopIsUpToDate: true
    },
    mutations: {
        SET_MEDALS(state, medals) {
            state.medals = medals
        },
        SET_SHOP_IS_UP_TO_DATE(state, shopIsUpToDate) {
            state.shopIsUpToDate = shopIsUpToDate
        }
    },
    actions: {
        /**
         * Fetch in state list of flux
         *
         * @param commit
         * @param routeParams
         */
        fetchMedals({commit}, routeParams) {
            return PodiumService.getMedals(routeParams)
                .then(response => {
                    switch (response.status) {
                        case 200 :
                            commit('SET_MEDALS', response.data)

                            return Promise.resolve(response.data)
                        case 204 :
                            commit('SET_SHOP_IS_UP_TO_DATE', false)
                            return Promise.resolve(response.data)
                    }
                })
                .catch(error => {
                    throw error.toJSON();
                })
        },
        fetchUserMedals({commit}, routeParams) {
        return PodiumService.getUserMedals(routeParams.user)
            .then(response => {
                commit('SET_MEDALS', response.data)
            })
                .catch(error => {
                    throw error;
                })
        },
    },
    getters: {
        getMedals: state => {
            return state.medals
        },
        getShopIsUpToDate: state => {
            return state.shopIsUpToDate
        }
    }
}
