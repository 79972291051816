// JWT DECODE
import VueJwtDecode from 'vue-jwt-decode'
// SERVICE
import AxiosService from '@/services/AxiosService'
import UserService from '@/services/UserService'

export default {
  state: {
    token: null,
    currentUser: null,
    currentCenter: null
  },
  mutations: {
    SET_USER_TOKEN(state, token) {
      state.token = token
      localStorage.setItem('auth.userToken', token)
    },
    SET_CURRENT_USER(state, data) {
      state.currentUser = data
      setLocalState('auth.user', data)
    },
    SET_CURRENT_CENTER(state, data) {
      state.currentCenter = data
      setLocalState('auth.center', data)
    }
  },
  actions: {
    login({ state, commit, dispatch }, { username, password, centerId }) {
      var data = {
        username: username,
        password: password,
        centerId: centerId
      }

      // Call axios
      return AxiosService.getToken(data)
        .then((response) => {
          // Set in store and local storage token
          commit('SET_USER_TOKEN', response.data.token)
          // Destroy temp user
          commit('SET_TEMP_USER', null)

          // Get user ID from token
          var dataToken = VueJwtDecode.decode(state.token)

          if(!dataToken.centerId) {
            return Promise.reject(new Error('access refused'))
          }

          // Set current Center
          commit('SET_CURRENT_CENTER', dataToken.centerId)

          // Get user form token
          let model = {
            centerId : centerId,
            dataToken: dataToken
          }

          return Promise.resolve(dispatch('getUserFromToken', model))
        })
        .catch((error) => {
          if (error && error.code === 401) {
            commit('SET_USER_TOKEN', null)
          }

          // Return error for login methods in login.vue
          return Promise.reject((error.response) ? error.response.data : error)
        })
    },
    /**
     * Get user by token (id)
     *
     * @param commit
     * @param model
     * @returns {*}
     */
    getUserFromToken({commit}, model)
    {
      // Call axios (user)
      return UserService
        .getUser(model.dataToken.id)
        .then((response) => {
          commit('SET_CURRENT_USER', response.data)
        })
        .catch((error) => {
          if (error && error.code === 401) {
            commit('SET_CURRENT_USER', null)
          }

          // Return error for login methods in login.vue
          return Promise.reject(error)
        })
    },
    // Logs out the current user.
    logout({ commit }, isRedirect) {
      commit('SET_USER_TOKEN', null)
      commit('SET_CURRENT_USER', null)

      // Redirect login
      if(isRedirect) {
        window.location.href = 'login'
      }
      // Clear local storage
      window.localStorage.clear()

      // Clear all state
      if(isRedirect) {
        let clearState = {};
        Object.keys(this.$state).forEach(key => {
          clearState[key] = null
        });
        this.$store.replaceState(clearState)
      }
    }
  },
  getters: {
    /**
     * Whether the user is currently logged in.
     *
     * @param state
     * @returns {boolean}
     */
    loggedIn(state) {
      return !!state.currentUser
    },
    /**
     * Get current user
     *
     * @param state
     * @returns {null}
     */
    getCurrentUser(state) {
      return state.currentUser
    },
    /**
     * check if user is admin
     *
     * @param state
     * @returns {boolean}
     */
    userIsAdmin(state) {
      return !!(state.currentUser && state.currentUser.roles[0] === 'ROLE_ADMIN');
    },
    /**
     * check if user has role shop
     *
     * @param state
     * @returns {boolean}
     */
    userIsShop(state) {
      return !!(state.currentUser && state.currentUser.roles[0] === 'ROLE_SHOP');
    },
    /**
     * check if user has role marketing
     *
     * @param state
     * @returns {boolean}
     */
    userIsMarketing(state) {
      return !!(state.currentUser && state.currentUser.roles[0] === 'ROLE_MARKETING');
    }
  }
}

// ===
// Private helpers
// ===

/**
 * Set local state
 *
 * @param key
 * @param state
 */
function setLocalState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}
