<template>
  <div class="mt-4">
    <a href="/sales">
      <b-icon icon="chevron-left"></b-icon>
      Retour</a>

    <div v-if='showPodium' id="podium" class="container">
      <h3 class=" bolder text-center mb-5 text-uppercase">Podiums {{ podiumDate }}</h3>
      <div class="row">
        <div v-for="medalType in this.medalTypes" v-bind:key="medalType.name"
             class="col-12 col-lg-6 border text-center">
          <div clas="col-12">
            <div class="medal-name">
              <h5> {{ medalType.name }}</h5>
            </div>
          </div>
          <div class="col-6 mx-auto">
            <div class="ranking" :class="medalType.myRank > 3 ? 'disable' : ''">Mon classement:
              {{ medalType.myRank }} / {{
                totalShopsInCategory
              }}
            </div>
          </div>

          <div class="podium d-flex align-items-end justify-content-center">
            <div class="podium__item">
              <div class="podium__rank second" :class="medalType.myRank == 2 ? '': 'disable'">
                <div class="name position-absolute">
                  {{ medalType.silverMedal.name }}
                </div>
              </div>
            </div>
            <div class="podium__item">
              <div class="podium__rank first" :class="medalType.myRank == 1 ? '': 'disable'">
                <div class="name position-absolute">
                  {{ medalType.goldMedal.name }}
                </div>
              </div>
            </div>
            <div class="podium__item">
              <div class="podium__rank third" :class="medalType.myRank == 3 ? '': 'disable'">
                <div class="name position-absolute">
                  {{ medalType.bronzeMedal.name }}
                </div>
              </div>
            </div>
          </div>
          <br>
          <br>
        </div>
      </div>
    </div>
    <div v-else-if="error.enabled" id="podium">
      {{ error.message }}
    </div>
    <div v-else id="podium">
      Une erreur est survenu.
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import moment from 'moment';


export default {
  computed: {
    ...mapState(['CenterModule']),
    ...mapState(['PodiumModule']),
    ...mapState(['UserModule']),
  },
  data() {
    return {
      loader: {
        isLoading: false
      },
      selectedMedalType: {
        name: 'monthlyYield',
        label: 'Chiffre d’affaire / m² mensuel'
      },
      medalTypes: {
        'monthlyYield': {
          name: 'Chiffre d’affaire / m² mensuel',
          myRank: 0,
          goldMedal: {
            name: 'gold',
            height: '250px',
            rank: 1
          },
          silverMedal: {
            name: 'silver',
            height: '200px',
            rank: 2
          },
          bronzeMedal: {
            name: 'bronze',
            height: '150px',
            rank: 3
          },
        },
        'monthlyYieldEvolution': {
          name: 'Évolution chiffre d’affaires / m² mensuel',
          myRank: 0,
          goldMedal: {
            name: 'gold',
            height: '250px',
            rank: 1
          },
          silverMedal: {
            name: 'silver',
            height: '200px',
            rank: 2
          },
          bronzeMedal: {
            name: 'bronze',
            height: '150px',
            rank: 3
          },
        },
        'rollingYield': {
          name: 'Chiffre d’affaires / m² sur 12 mois',
          myRank: 0,
          goldMedal: {
            name: 'gold',
            height: '250px',
            rank: 1
          },
          silverMedal: {
            name: 'silver',
            height: '200px',
            rank: 2
          },
          bronzeMedal: {
            name: 'bronze',
            height: '150px',
            rank: 3
          },
        },
        'rollingYieldEvolution': {
          name: 'Évolution chiffre d’affaires / m² mensuel sur 12 mois',
          myRank: 0,
          goldMedal: {
            name: 'gold',
            height: '250px',
            rank: 1
          },
          silverMedal: {
            name: 'silver',
            height: '200px',
            rank: 2
          },
          bronzeMedal: {
            name: 'bronze',
            height: '150px',
            rank: 3
          },
        }
      },
      showPodium: false,
      shopIsUpToDate: false,
      totalShopsInCategory: 0,
      myShopName: '',
      podiumDate: '',
      error: {
        enabled: false,
        message: ''
      }
    }
  },
  created() {
    this.myShopName = JSON.parse(localStorage.getItem('auth.user')).shop.name
      this.fetchMedals({user: this.$store.getters.getCurrentUser.id})

  },

  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },

    /*----------------- MEDALS -------------------*/

    handleShowMedals() {
      this.shopIsUpToDate = this.$store.getters.getShopIsUpToDate;

      const medals = this.$store.getters.getMedals;

      this.totalShopsInCategory = medals.length;

      if (medals.length > 0 && this.shopIsUpToDate) {

        this.podiumDate = moment(medals.data[0].date).format('MMMM YYYY');

        medals.data.forEach(medal => {
          switch (medal.rank) {
            case 1:
              this.medalTypes[medal.type].goldMedal.name = medal.shop.name;
              break;
            case 2:
              this.medalTypes[medal.type].silverMedal.name = medal.shop.name;
              break;
            case 3:
              this.medalTypes[medal.type].bronzeMedal.name = medal.shop.name;
              break;
          }
          if (medal.shop.name === this.myShopName) {
            this.medalTypes[medal.type].myRank = medal.rank;
          }
          this.showPodium = true;
        })
      }
    },
    fetchMedals(searchParams) {
      this.$store.dispatch('fetchMedals', {...searchParams})
          .then((response) => {
            if (typeof response === "string") {
              this.error.message = response;
              this.error.enabled = true;
            }
            this.handleShowMedals();
          })
    },
    capitalizeText(initialText) {
      return initialText.charAt(0).toUpperCase() + initialText.slice(1);
    }
  }
};
</script>

<style scoped>

</style>
