<template>
  <div class="container" id="page-404">
    <div class="row h-100">
      <div class="col-8 mx-auto my-auto text-center">
        <img class="mr-5" :src="require('../../../public/images/'+ ChannelModule.channel.code +'/logo.svg')" alt="Logo" style="width: 70%">

        <h1 class="pt-5 pb-5">Il semblerait que vous soyez perdu !</h1>

        <a v-if="$store.getters.loggedIn" class="" href="/">Retour à l'accueil ?</a>
        <a v-else class="" href="/login">Retour à l'accueil ?</a>
      </div>
    </div>

  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'NotFound',
  computed: {
    ...mapState(['ChannelModule'])
  }
}

</script>
