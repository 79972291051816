import SaleService from '@/services/SaleService'

export default {
  state: {
    shop: {},
    sales: [],
    rollingSales: []
  },
  mutations: {
    SET_SALES_SHOP(state, shop)
    {
      state.shop = shop
    },
    SET_SALES(state, sales) {
      state.sales = sales
    },
    SET_ROLLING_SALES(state, rollingSales)
    {
      state.rollingSales = rollingSales
    }
  },
  actions: {
    fetchRollingSales({commit}, params)
    {
      return SaleService.getRollingSalesForShops(params).then(response => {
        commit('SET_ROLLING_SALES', response.data)
      })
    },
    /**
     * Get shop
     *
     * @param commit
     * @param model
     * @returns {*}
     */
    fetchSalesShop({ commit }, model)
    {
      return SaleService.getShop(model.shopId)
        .then(response => {
          commit('SET_SALES_SHOP', response.data)

          return Promise.resolve(response.data)
        })
        .catch(error => {
          console.log('There was an error on sales get shop: ', error)
        })
    },
    /**
     * Fetch in state list of sales
     *
     * @param commit
     * @param routeParams
     */
    fetchSales({ commit }, routeParams) {
      return SaleService.getSales(routeParams)
        .then(response => {
          commit('SET_SALES', response.data)
        })
        .catch(error => {
          console.log('There was an error on sales list: ', error)
        })
    },
    /**
     * Create or update sales
     *
     * @param commit
     * @param model
     */
    createOrUpdateSales({ commit }, model)
    {
      if(model.userId !== null) {
        return SaleService.create(model)
          .then((response) => {
            // Check if has errors on form
            if(response.data.error) {
              return Promise.reject(response.data.error)
            }

            commit('SET_SALES', model.data)
            return Promise.resolve()
          })
      }
    },
    /**
     * Send email ask for help
     *
     * @param commit
     * @param model
     */
    // eslint-disable-next-line no-unused-vars
    sendEmailAskForHelp({ commit }, model)
    {
        return SaleService.sendEmailAskForHelp(model)
    }
  },
  getters: {
    getCountSales: state => {
      return state.sales.length
    },
    getCountSalesWithVat : state =>  {
      return state.sales.reduce((result, sale) => {
        result += Number(sale.totalWithVat)

        return Math.round(result)
      }, 0)
    },
    getCountSalesWithoutVat: state => {
      return state.sales.reduce((result, sale) => {
        result += Number(sale.totalWithoutVat)

        return Math.round(result)
      }, 0)
    },
    getRollingSales: state => {
      return state.rollingSales;
    }
  }
}
