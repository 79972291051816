<template>
  <div id="app">
    <Header v-if="this.$route.name && $store.getters.loggedIn" class="mobile"/>
    <div class="h-100" v-if="$store.getters.loggedIn && this.$route.name">
      <div class="wrapper d-flex w-100" v-if="$store.getters.loggedIn">
        <div class="main" :class="($store.getters.userIsShop) ? 'main-shop' : ''">
          <Header v-if="this.$route.name"/>
          <div class="app-container position-relative pl-2 pl-md-5 pr-2 pr-md-5 pb-2 pb-md-5 d-flex flex-column">
            <router-view/>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="h-100">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false
    }
  },
  created() {
  },
  methods: {
  },
  components: {
    Header: () => import('@/components/layouts/Header')
  },
}

</script>
