import AxiosService from '@/services/AxiosService'

export default {
    /**
     * Get list Flux on period
     *
     * @param routeParams
     * @returns {*}
     */
    getMedals(routeParams)
    {
        return AxiosService.callApi().get('/podium/' + routeParams.user);
    },

    getUserMedals(userId)
    {
        return AxiosService.callApi().get('/medals/' + userId);
    }
}
