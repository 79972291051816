import AxiosService from '@/services/AxiosService'

export default {
  /**
   * Get shop by id
   *
   * @param shopId
   * @returns {*}
   */
  getShop(shopId)
  {
    return AxiosService.callApi().get('/sales/shop/' + shopId)
  },
  /**
   * Get month less one list of sales
   *
   * @param routeParams
   * @returns {*}
   */
  getSales(routeParams)
  {
    return AxiosService.callApi().get('/sales/list/' + routeParams.month + '/' + routeParams.year + '/' + routeParams.shopId)
  },
  /**
   * Gets the CA of last 12 months of a given number of shops
   *
   * @param data
   * @returns {*}
   */
  getRollingSalesForShops(data)
  {
    return AxiosService.callApi().post('sales/rolling', data)
  },
  /**
   * Create sale
   *
   * @param data
   * @returns {*}
   */
  create(data)
  {
    return AxiosService.callApi().post('/sales', data)
  },
  delete(saleId)
  {
    return AxiosService.callApi().delete('/sales/' + saleId)
  },
  sendEmailAskForHelp(model) {
    return AxiosService.callApi().post('/sales/askHelp', model)
  }
}
