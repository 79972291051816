import store from '../store/index'

// APP
import NotFound from '@/views/app/404'
import Error from '@/views/app/error'
import ForgotPassword from '@/views/app/ForgotPassword'
import ChangePassword from '@/views/app/changePassword'

// ACCOUNT
import Login from '@/views/auth/Login'

// SALE
import Sale from '@/views/pages/sale/sales'

// User
import UserProfile from '@/views/pages/user/UserProfile'

//Podium
import Podium from "@/components/pages/podium/podium";
import Medals from "@/components/pages/podium/medals";

//Histogram
import HistogramCA from "@/components/pages/histogram/histogramCA";

const AppRoutes = [
    {
        path: '*',
        component: NotFound
    },
    {
        path: '/error',
        name: 'error',
        component: Error
    },
    {
        path: '/',
        redirect: {name: 'login'},
        component: Error
    }
]

const AccountRoutes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            authRequired: false,
        }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: {
            authRequired: false,
            routePublic: true
        }
    },
    {
        path: '/change-password/:token/:id',
        name: 'change-password',
        component: ChangePassword,
        meta: {
            routePublic: true,
            authRequired: false
        }
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve() {
                store.dispatch('logout', true)
            }
        }
    }
]

const PagesRoutes = [
    {
        path: '/sale/:id',
        name: 'sale',
        component: Sale,
        meta: {
            authRequired: true
        }
    }
]

const UserRoutes = [
    {
        path: '/user/profile/:id',
        name: 'user-profile',
        component: UserProfile,
        meta: {
            authRequired: true
        }
    }
]

const PodiumRoutes = [
    {
        path: '/podium',
        name: 'podium',
        component: Podium,
        meta: {
            authRequired: true
        }
    }
]

const MedalRoutes = [
    {
        path: '/medals',
        name: 'medals',
        component: Medals,
        meta: {
            authRequired: true
        }
    }
]

const HistoRoutes = [
    {
        path: '/histogram',
        name: 'histogram',
        component: HistogramCA,
        props: true,
        meta: {
            authRequired: true,
        }
    }
]

const allRoutes = [
    ...AppRoutes,
    ...AccountRoutes,
    ...PagesRoutes,
    ...UserRoutes,
    ...PodiumRoutes,
    ...MedalRoutes,
    ...HistoRoutes
]

export {allRoutes}
