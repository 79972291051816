<template>
    <div id="page-export" class="mt-4">
        {{ /* Loader */ }}
      <a href="/sales">
        <b-icon icon="chevron-left"></b-icon>
        Retour</a>
        <div class="text-center">
            <h2>Histogramme CA HT</h2>
        </div>
        <loading
                :active.sync="loader.isLoading"
                :can-cancel="false"
                :is-full-page="loader.fullPage"
                :opacity="loader.opacity"
                :color="loader.color"
        ></loading>
        <ApexChart type="bar" height="500" :options="chartOptions" :series="series"></ApexChart>
    </div>
</template>

<script>

import moment from 'moment'
import {fr} from 'vuejs-datepicker/dist/locale'
import {mapState} from "vuex";


export default {
    components: {
        ApexChart: () => import('vue-apexcharts'),
        Loading: () => import('vue-loading-overlay')
    },
    computed: {
        ...mapState(['CenterModule']),
        ...mapState(['CategoryModule']),
    },
    data() {
        return {
            apexChartFlux: [],
            apexChartStats: [],
            datepickerLanguage: fr,
            loader: {
                opacity: 0.9,
                isLoading: false,
                fullPage: true,
                color: '#28a745'
            },
            configExport: {
                date: null,
                formatDate: 'MM/yyyy',
                isOk: false,
                showTabs: false,
                options: null,
                comparativeDate: null
            },
            dataPage: {
                title: 'Histogramme CA HT',
            },
            centers: [],
            categories: [],
            shops: [],
            selectedShops: [],
            series: [
                {
                    name: '',
                    data: []
                },
                {
                    name: '',
                    data: []
                }
            ],
            chartOptions: {
                colors: ["#27495C", "#37A86C"],
                legend: {
                    position: 'top',
                },
                chart: {
                    height: '500',
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        if (val) {
                            let parts = val.toString().split(".");
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                            return parts.join(".");
                        }
                        return '0';
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '12px',
                        colors: ["#304758"]
                    }
                },
                xaxis: {
                    categories: [],
                    position: 'bottom',
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        fill: {
                            type: 'gradient',
                            gradient: {
                                colorFrom: '#D8E3F0',
                                colorTo: '#BED1E6',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            }
                        }
                    },
                    tooltip: {
                        enabled: true,
                    }
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: true,
                        formatter: function (val) {
                            if (val) {
                                let parts = val.toString().split(".");
                                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                                return parts.join(".");
                            }
                            return '0';
                        }
                    }

                },
                title: {
                    text: '',
                    floating: true,
                    offsetY: 330,
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },
        }
    },
    created() {
        let shopId = JSON.parse(localStorage.getItem('auth.user')).shop.id;
        let dateObj = new Date()
        this.fetchData(shopId, dateObj);
    },
    methods: {
        fetchData(shopId, dateObj) {
            this.$store.dispatch('fetchRollingSales', {
                shops: [shopId],
                date: moment(dateObj).format('YYYY-MM-DD')
            }).then(() => {
                this.handleData(this.$store.getters.getRollingSales)
            })
        },
        handleData(data) {
            this.series = [];
            this.chartOptions.xaxis.categories = [];

            let dataSets = [];
            let newCategories = [];

            data.forEach((dataSet, index) => {
                dataSets[index] = [];
                Object.keys(dataSet).forEach((date, key) => {
                    dataSets[index].push(dataSet[date]);
                    newCategories[key] = this.capitalizeText(moment().month(date.split('-')[1] - 1).format('MMMM'));
                })
            })

            dataSets.map((dataSet, index) => {
                let label = !index ? "Année n-1" : "Année en cours";
                this.series = [...this.series, {name: label, data: dataSet}]
            })
            this.chartOptions = {...this.chartOptions, ...{xaxis: {categories: [...newCategories]}}}
        },
        formatNumbersWithSpacePerThousands(number) {
            if (number) {
                let parts = number.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                return parts.join(".");
            }
            return '0';
        },
        capitalizeText(initialText) {
            return initialText.charAt(0).toUpperCase() + initialText.slice(1);
        }
    }
}
</script>
