// VUE
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
// ROUTES
import { allRoutes } from './routes'

// Router
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: allRoutes
})

// Config redirect (hook)
router.beforeEach((routeTo, from, next) => {

  // Check if auth is required on this route
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)
  const routeAdmin = routeTo.matched.some((route) => route.meta.routeAdmin)
  const routeMarketing = routeTo.matched.some((route) => route.meta.routeMarketing)
  const routePublic = routeTo.matched.some((route) => route.meta.routePublic)

  // User data
  var userLoggedIn = store.getters['loggedIn']
  var userIsMarketing = store.getters['userIsMarketing']
  var userIsShop = store.getters['userIsShop']

  // If auth isn't required for the route, just continue.
  if (!authRequired) {
    if (userLoggedIn) {
      return next({ name: 'sale' })
    } else {
      return next()
    }
  }

  if(routePublic) {
    return next()
  }

  // If auth is required and the user is logged in...
  if (userLoggedIn) {
    // If user is admin and route protect by role admin
    if(userIsShop && routeAdmin) {
      return next({ name: 'sale' })
    } else if (userIsMarketing && routeAdmin && !routeMarketing) {
      return next({ name: 'export-marketing-index' })
    } else {
      next()
    }
  } else {
    // If auth is required and the user is NOT currently logged in,
    // redirect to login.
    if(!routePublic) {
      redirectToLogin()
    }
  }

  function redirectToLogin() {
    // Pass the original route to the login component
    next({ name: 'login' })
  }
})

// Config for method beforeResolve (hook)
router.beforeResolve(async (routeTo, routeFrom, next) => {
  // Create a `beforeResolve` hook, which fires whenever
  // `beforeRouteEnter` and `beforeRouteUpdate` would. This
  // allows us to ensure data is fetched even when params change,
  // but the resolved route does not. We put it in `meta` to
  // indicate that it's a hook we created, rather than part of
  // Vue Router (yet?).
  try {
    // For each matched route...
    for (const routes of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (routes.meta && routes.meta.beforeResolve) {
          routes.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
})

export default router
