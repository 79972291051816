import store from '../store'
import Router from '../router'

export default {
  async callApiChannel() {
    let hostname = window.location.origin
    await store.dispatch('fetchChannel', {
      hostname: (hostname.indexOf("https://") === 0) ? hostname.replace('https://', '') : hostname.replace('http://', '')
    })
      .then((response) => {
        // CSS
        const centerTheme = () => import('../../assets/scss/'+ response.code + '/main.scss')
        centerTheme();
      })
      .catch(() => {
        // Redirect error page
        Router.push({ name: 'error' })
      })
  }
};
