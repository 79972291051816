<template>
    <div id="page-sale">
        <div v-if="this.saleError.isError" class="text-center alert alert-danger mt-4 col-8 offset-2" role="alert">
            {{ this.saleError.errorMessage }}
        </div>

        {{ /* BUTTON HELP (MOBILE) */ }}
        <div class="button-help-mobile text-right mb-3">
            <b-icon class="mr-2" icon="question-square" font-scale="1.5" variant="danger" animation="throb"
                    v-on:click="clickButtonHelpMobile"></b-icon>
        </div>
        {{ /* DIV HELP (MOBILE) */ }}
        <div
                class="div-help-mobile"
                :class="(showHelpMobile) ? 'collapsed' : ''"
        >
            <div class="card app-box-shadow p-3">
                <div class="text-right">
                    <b-icon
                            v-on:click="closeBlockHelpMobile"
                            class=""
                            icon="x-square"
                            font-scale="1.5"
                            variant="info"
                    ></b-icon>
                </div>

                <hr v-if="!SaleModule.shop.isMonth"/>

                <div class="" v-if="askForHelp.isEmailSend">
                    <div class="alert alert-success" role="alert">
                        <h6 class="alert-heading">Demande d'aide envoyée avec succès</h6>
                        <hr/>
                        <p class="mb-0">
                            <button v-on:click="resetEmailSend" class="btn btn-outline-info ml-2">
                                Effectuer une autre demande
                            </button>
                        </p>
                    </div>
                </div>
                <div v-else class="mt-1">
                    <button v-on:click="askForHelp.clicked = true" class="btn btn-outline-danger">
                        <b-icon class="mr-2" icon="question-square" font-scale="1.5" variant="danger"
                                animation="throb"></b-icon>
                        Demander de l'aide
                    </button>
                </div>

                <div class="legend mt-4">
                    <div>
                        <b-icon icon="pencil-square" font-scale="1.5" variant="info"></b-icon>
                        = Saisie en cours
                    </div>
                    <div class="mt-2">
                        <b-icon icon="check-circle" font-scale="1.5" variant="primary"></b-icon>
                        = CA à jour
                    </div>
                    <div class="mt-2">
                        <b-icon icon="person" font-scale="1.5" variant="warning"></b-icon>
                        = Rempli par le Centre
                    </div>
                    <div class="mt-2">
                        <b-icon icon="exclamation-triangle" font-scale="1.5" variant="danger"></b-icon>
                        = CA en retard
                    </div>
                </div>

                {{ /* BUTTON SUBMIT */ }}
                <div class="d-flex justify-content-end mb-3 mt-4 mr-3">
                    <button class="btn btn-success submit-all-sale-desktop"
                            v-on:click="submitForm(SaleModule.shop.isMonth)">
                        Valider la saisie chiffre d'affaires
                    </button>
                </div>
            </div>
        </div>

        <div class="total-month-mobile mb-3" v-if="!SaleModule.shop.isMonth">
            <div class="card">
                <div class="card-body">
                    <h6 class="font-weight-bold text-center">Total du mois en cours</h6>

                    <div class="d-flex justify-content-between">
            <span class="big-text">
              {{ this.$store.getters.getCountSalesWithoutVat }} €
            </span>
                        <span class="ml-4">&nbsp;&nbsp;HT</span>
                    </div>

                    <div class="d-flex justify-content-between">
            <span class="big-text">
              {{ this.$store.getters.getCountSalesWithVat }} €
            </span>
                        <span class="ml-4">TTC</span>
                    </div>
                </div>
            </div>
        </div>

        {{ /* TOTAL MONTH */ }}
        <div class="d-md-flex mt-md-3" v-if="initialDataHasLoaded">
            <div class="col-12 col-md-12 col-xl-10 p-0">
                {{ /* CARD SALES */ }}
                <div class="card border-0">
                    <div class="p-md-3">
                        {{ /* HEADER CARD */ }}
                        <div class="d-md-flex justify-content-between">
                            <div class="w-100">
                                <h3 class="text-center text-md-left pt-3 pt-md-0">
                                    <b-icon class="mr-3" icon="bar-chart-line" font-scale="1.5"
                                            variant="primary"></b-icon>
                                    <span>
                    Saisie Chiffres d'affaires
                  </span>
                                </h3>

                                {{ /* DATE LIMIT SALES */ }}
                                <div class="small">
                                    <div class="text-center text-md-left"
                                         v-if="!SaleModule.shop.isMonth && SaleModule.shop.dateLimitSale">
                                        Vous avez jusqu'au
                                        {{ getDateLimitPeriod(SaleModule.shop.dateLimitSale, 'DD MMMM YYYY') }}
                                        pour remplir votre chiffre d'affaires
                                    </div>
                                </div>
                            </div>


                            {{ /* BUTTON CHANGE PERIOD */ }}
                            <div class="mt-4 mt-lg-0">
                                <div class="text-center mr-2 font-weight-bold">Choisir une période</div>
                                <Datepicker
                                        @selected="clickDatePicker"
                                        class="mt-1 mr-2 text-center text-lg-right"
                                        id="date-sale-month"
                                        :format="formatDatepicker"
                                        :minimumView="'month'"
                                        :maximumView="'month'"
                                        :language="datepicker.language"
                                        :value="datepicker.defaultDate"
                                        input-class="text-center"
                                        :disabledDates="datepicker.disabledDates"
                                ></Datepicker>
                            </div>
                        </div>

                        <div class="col-12 text-right">
                            <a class="btn btn-secondary mr-2" href="/podium">Podiums
                            </a>
                            <a href="/medals" class="btn btn-secondary mr-2">Mes médailles
                            </a>
                            <a href="/histogram" class="btn btn-secondary">Histogramme CA HT
                            </a>
                        </div>

                        <div class="text-warning text-center">
                            <h3>
                                {{ upperCaseMonthStr }}
                            </h3>
                        </div>
                        <hr/>
                        <div v-if="SaleModule.shop.doubleSaleEntry"
                             class="text-center alert alert-info mt-4 col-12 mb-0" role="alert">
                            {{ page.doubleEntryMessage }}
                        </div>
                        {{ /* IF SHOP IS MONTH */ }}
                        <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-0"
                             v-if="loader.isLoading">
                            <div class="text-center">
                                <b-spinner variant="success" label="Spinning"></b-spinner>
                                <div class="text-primary">Chargement en cours ...</div>
                            </div>
                        </div>
                        <div v-else>
                            <div v-if="SaleModule.shop.isMonth">
                                <div class="d-flex justify-content-center">
                                    <div class="d-flex flex-column justify-content-center">
                                        <b-icon
                                                v-on:click="period.monthSubtract += 1
                        goToPreviousMonth()"
                                                class="button-period-sales button-period-sales-month mr-2 mr-md-3 p-2"
                                                icon="chevron-left"
                                                font-scale="3"
                                                variant="secondary"
                                        ></b-icon>
                                    </div>

                                    {{ /* CARD */ }}
                                    <div
                                            class="card card-sales-month d-flex flex-column justify-content-between app-box-shadow border-0 col-8 col-md-4 mb-3 p-2"
                                            :class="[
                        (saleModel.userWithVat !== null || saleModel.userWithoutVat !== null) ? 'card-fill' : '',
                        (saleModel.bgColor !== null) ? saleModel.bgColor : ''
                    ]"
                                    >
                                        <div class="text-white text-center mt-2 mb-2"><i>Quel a été votre chiffre
                                            d'affaires ?</i></div>
                                        {{ /* ICON */ }}
                                        <div class="icon d-flex justify-content-center">
                                            <div v-if="saleModel.bgColor === 'bg-danger'">
                                                <b-icon icon="exclamation-triangle" font-scale="1.5"
                                                        variant="white"></b-icon>
                                            </div>
                                            <div v-else-if="saleModel.bgColor === 'bg-primary'">
                                                <b-icon icon="check-circle" font-scale="1.5" variant="white"></b-icon>
                                            </div>
                                            <div v-else-if="saleModel.bgColor === 'bg-warning'">
                                                <b-icon icon="person" font-scale="1.5" variant="white"></b-icon>
                                            </div>
                                        </div>

                                        {{ /* DATE */ }}
                                        <h3 class="text-white text-center">
                                            {{ capitalizeText(getDatePeriod('MMMM YYYY')) }}
                                        </h3>

                                        <form @submit.prevent="submitForm(true)">
                                            <div class="d-flex justify-content-center">
                                                <div class="input-group w-75" v-if="checkUserCanEdit(saleModel)">
                                                    <input
                                                            type="number"
                                                            step="0.01"
                                                            class="form-control border-0"
                                                            v-model="saleModel.totalWithoutVat"
                                                            placeholder="Chiffre d'affaires"
                                                            v-on:keyup="onChangeInputWithoutVat($event, saleModel)"
                                                    >
                                                    <div class="input-group-append">
                                                        <div class="input-group-text text-grey bg-white border-0">HT
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-inline-flex" v-else>
                                                    <div class="align-self-center">
                                                        <span class="text-light big-text ">{{
                                                            formatNumbersWithSpacePerThousands(saleModel.totalWithoutVat)
                                                            }} €</span>
                                                    </div>
                                                    <span class="text-light ml-4">HT</span>
                                                </div>
                                            </div>

                                            <div class="d-flex justify-content-center mt-4">
                                                <div class="input-group w-75" v-if="checkUserCanEdit(saleModel)">
                                                    <input
                                                            type="number"
                                                            step="0.01"
                                                            class="form-control border-0"
                                                            v-model="saleModel.totalWithVat"
                                                            placeholder="Chiffre d'affaires"
                                                            v-on:keyup="onChangeInputWithVat($event, saleModel)"
                                                    >
                                                    <div class="input-group-append">
                                                        <div class="input-group-text text-grey bg-white border-0">TTC
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex justify-content-between" v-else>
                                                    <span class="text-light big-text">{{
                                                        formatNumbersWithSpacePerThousands(saleModel.totalWithVat)
                                                        }} €</span>
                                                    <span class="text-light ml-4">TTC</span>
                                                </div>

                                                <input type="hidden" v-model="saleModel.id">
                                            </div>

                                            <div class="text-center mt-1" v-if="errorFormSales">
                                                <p class="form-error"
                                                   :class="(saleModel.bgColor === 'bg-danger') ? 'text-warning' : 'text-danger'">
                                                    {{ errorFormSales }}
                                                </p>
                                            </div>

                                            <div class="d-flex justify-content-center mt-5"
                                                 v-if="$store.getters.userIsAdmin || saleModel.bgColor !== 'bg-primary'">
                                                <input
                                                        type="submit"
                                                        class="btn ml-3 mt-2"
                                                        v-if="checkUserCanEdit(saleModel) && !saleModel.error"
                                                        :class="(saleModel.bgColor !== '') ? 'btn-outline-light' : 'btn-primary'"
                                                        value="Valider"
                                                />
                                                <div v-else-if="saleModel.error">
                                                    <span class="text-danger">{{ saleModel.error }}</span>
                                                </div>
                                            </div>
                                        </form>
                                        <div class="text-white small-text text-center"
                                             v-if="saleModel.bgColor === 'bg-warning'">
                                            <template v-if="$store.getters.userIsAdmin">
                                                Chiffre d'affaires rempli par l'utilisateur
                                            </template>
                                            <template v-else>
                                                Chiffre d'affaires rempli par un administrateur
                                            </template>
                                        </div>
                                        <div class="text-white small-text text-center" v-else>
                                            <div v-if="SaleModule.shop.dateLimitSale">
                                                Vous avez jusqu'au
                                                {{ getDateLimitPeriod(SaleModule.shop.dateLimitSale, 'DD MMMM YYYY') }}
                                                pour remplir votre chiffre d'affaires
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-column justify-content-center">
                                        <b-icon
                                                v-on:click="period.monthSubtract -= 1
                        goToNextMonth()"
                                                class="button-period-sales button-period-sales-month ml-2 ml-md-3 p-2"
                                                :class="isNextMonthDisabled ? 'button-period-disabled' : ''"
                                                icon="chevron-right"
                                                font-scale="3"
                                                variant="secondary"
                                        ></b-icon>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <form ref="formSales" @submit.prevent="submitForm(false)" class="form-sales w-100">
                                    <div class="d-flex flex-wrap mt-4 pb-4 w-100">
                                        {{ /* CARD SALES BY DAY */ }}
                                        <div
                                                class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-3"
                                                v-for="(obj, index) in listByDay" :key="index"
                                        >
                                            <div
                                                    class="card card-sales app-box-shadow border-0 p-2 mt-3"
                                                    v-on:click="cardActiveEvent(index, obj)"
                                                    :class="[
                          (card.index === index) ? 'card-active' : '',
                          checkIfDayIsFilled(obj) ? 'card-fill' : '',
                          (obj.bgColor !== null) ? obj.bgColor : ''
                      ]"
                                            >
                                                {{ /* DATE AND ICONS */ }}
                                                <div class="">
                                                    <div class="date"
                                                         :class="(obj.bgColor === 'bg-danger') ? 'text-white' : ''">
                                                        <div class="sale-date-desktop text-center">
                                                            {{
                                                            obj.date | moment((card.index === index || (obj.userWithVat !== null || obj.userWithoutVat !== null)) ? 'dddd DD' : 'dddd DD')
                                                            }}
                                                        </div>
                                                        <div class="sale-date-mobile text-center">
                                                            {{ obj.date | moment('dddd DD') }}
                                                        </div>
                                                    </div>

                                                    <div class="icon-desktop icon">
                                                        <div v-if="obj.bgColor === 'bg-info'">
                                                            <b-icon icon="pencil-square" font-scale="1.5"
                                                                    variant="white"></b-icon>
                                                        </div>
                                                        <div v-else-if="obj.bgColor === 'bg-danger'">
                                                            <b-icon icon="exclamation-triangle" font-scale="1.5"
                                                                    variant="white"></b-icon>
                                                        </div>
                                                        <div v-else-if="obj.bgColor === 'bg-primary'">
                                                            <b-icon icon="check-circle" font-scale="1.5"
                                                                    variant="white"></b-icon>
                                                        </div>
                                                        <div v-else-if="obj.bgColor === 'bg-warning'">
                                                            <b-icon icon="person" font-scale="1.5"
                                                                    variant="white"></b-icon>
                                                        </div>
                                                    </div>
                                                </div>

                                                {{ /* INPUTS */ }}
                                                <div class="inputs mt-3">
                                                    <div class="input-group" v-if="checkUserCanEdit(obj)">
                                                        <input
                                                                type="number"
                                                                step="0.01"
                                                                class="form-control border-0"
                                                                v-model="obj.totalWithoutVat"
                                                                placeholder="CA"
                                                                v-on:keyup="onChangeInputWithoutVat($event, listByDay[index])"
                                                                v-on:focusout="onFocusInputWithoutVat()"
                                                        >
                                                        <input type="hidden" v-model="obj.userWithoutVat">
                                                        <div class="input-group-append">
                                                            <div class="input-group-text text-grey bg-white border-0">
                                                                &nbsp;HT
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex justify-content-between" v-else>
                            <span class="text-light big-text">
                              {{ (obj.totalWithoutVat) ? formatNumbersWithSpacePerThousands(obj.totalWithoutVat) : 0 }} €
                            </span>
                                                        <span class="text-light">&nbsp;HT</span>
                                                    </div>

                                                    <div class="input-group mt-2" v-if="checkUserCanEdit(obj)">
                                                        <input
                                                                type="number"
                                                                step="0.01"
                                                                class="form-control border-0"
                                                                v-model="obj.totalWithVat"
                                                                placeholder="CA"
                                                                v-on:keyup="onChangeInputWithVat($event, listByDay[index])"
                                                                v-on:focusout="onFocusInputWithVat()"
                                                        >
                                                        <input type="hidden" v-model="obj.userWithVat">
                                                        <div class="input-group-append">
                                                            <div class="input-group-text text-grey bg-white border-0">
                                                                TTC
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <div class="d-flex justify-content-between">
                              <span class="text-light big-text">
                                {{ (obj.totalWithVat) ? formatNumbersWithSpacePerThousands(obj.totalWithVat) : 0 }} €
                              </span>
                                                            <span class="text-light">TTC</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {{ /* ERROR ON SALES */ }}
                                                <div class="text-light text-center" v-if="obj.error">
                                                    <b-alert class="p-0 mt-1" show variant="danger">{{
                                                        obj.error
                                                        }}
                                                    </b-alert>
                                                </div>

                                                <div class="total-mobile mt-4 text-white">
                                                    <div class="d-flex justify-content-center">
                                                        {{
                                                        (obj.totalWithoutVat || obj.totalWithoutVat == 0) ? obj.totalWithoutVat + ' €' : ''
                                                        }}
                                                        <span class="ml-2">{{
                                                            (obj.totalWithoutVat || obj.totalWithoutVat == 0) ? ' (HT)' : ''
                                                            }}</span>
                                                    </div>
                                                    <div class="d-flex justify-content-center">
                                                        {{
                                                        (obj.totalWithVat || obj.totalWithVat == 0) ? obj.totalWithVat + ' €' : ''
                                                        }}
                                                        <span class="ml-2">{{
                                                            (obj.totalWithVat || obj.totalWithVat == 0) ? ' (TTC)' : ''
                                                            }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2">
                {{ /* BUTTON HELP */ }}
                <div class="card app-box-shadow p-2 block-help-desktop position-fixed bg-white">
                    <div v-if="askForHelp.isEmailSend">
                        <div class="alert alert-success" role="alert">
                            <h6 class="alert-heading">Demande d'aide envoyée avec succès</h6>
                            <hr/>
                            <p class="mb-0">
                                <button v-on:click="resetEmailSend" class="btn btn-outline-info ml-2">
                                    Effectuer une autre demande
                                </button>
                            </p>
                        </div>
                    </div>
                    <div v-else class="mt-1">
                        <button v-on:click="askForHelp.clicked = true"
                                class="btn btn-block btn-outline-danger text-left">
                            <b-icon class="mr-4" icon="question-square" font-scale="1.5" variant="danger"
                                    animation="throb"></b-icon>
                            <span>Demander de l'aide</span>
                        </button>
                    </div>

                    <hr v-if="!SaleModule.shop.isMonth"/>

                    <div class="total-month" v-if="!SaleModule.shop.isMonth">
                        <div class="card bg-white border-0">
                            <div class="card-body text-left p-0">
                                <h6 class="font-weight-bold text-left">Total du mois en cours</h6>

                                <div class="text-center" v-if="loader.isLoading">
                                    <b-spinner variant="success" label="Spinning"></b-spinner>
                                    <div class="text-primary">Chargement en cours ...</div>
                                </div>
                                <div v-else>
                                    <div class="text-right">
                  <span class="big-text">
                    {{ formatNumbersWithSpacePerThousands(totalMonthWithoutVat) }} €
                  </span>
                                        <span>&nbsp;&nbsp;HT</span>
                                    </div>

                                    <div class="text-right">
                  <span class="big-text">
                    {{ formatNumbersWithSpacePerThousands(totalMonthWithVat) }} €
                  </span>
                                        <span>TTC</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr/>

                    <div class="legend">
                        <div class="mb-2 text-smaller"><strong>Légende</strong></div>
                        <div>
                            <b-icon icon="pencil-square" font-scale="1" variant="info"></b-icon>
                            = Saisie en cours
                        </div>
                        <div class="mt-2">
                            <b-icon icon="check-circle" font-scale="1" variant="primary"></b-icon>
                            = CA à jour
                        </div>
                        <div class="mt-2">
                            <b-icon icon="person" font-scale="1" variant="warning"></b-icon>
                            = Rempli par le Centre
                        </div>
                        <div class="mt-2">
                            <b-icon icon="exclamation-triangle" font-scale="1" variant="danger"></b-icon>
                            = CA en retard
                        </div>
                    </div>

                    <hr/>

                    {{ /* BUTTON SUBMIT */ }}
                    <div class="mb-2">
                        <button
                                v-if="
                  !SaleModule.shop.isMonth
                  && (getLastDayInMonthPeriod !== this.$store.getters.getCountSales)
                  && !errorSales
                "
                                class="btn btn-success submit-all-sale-desktop"
                                v-on:click="submitForm(SaleModule.shop.isMonth)"
                        >
                            Valider la saisie chiffre d'affaires
                        </button>
                        <div class="text-center" v-else-if="errorSales">
                            <b-alert show variant="danger">
                                <div class="d-flex justify-content-between">
                                    <span>Erreur(s) de saisie</span>
                                    <b-icon icon="exclamation-triangle" font-scale="1.5" variant="dark"></b-icon>
                                </div>
                            </b-alert>
                        </div>
                        <div class="text-center" v-else-if="!SaleModule.shop.isMonth">
                            <b-alert show variant="success">
                                <div class="d-flex justify-content-between">
                                    <span class="mr-2">Votre mois est validé</span>
                                    <b-icon icon="check-circle" font-scale="1.5" variant="primary"></b-icon>
                                </div>
                            </b-alert>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="loader-spinner d-flex justify-content-center flex-column text-center mt-0" v-else>
            <div class="text-center">
                <b-spinner variant="success" label="Spinning"></b-spinner>
                <div class="text-primary">Chargement en cours ...</div>
            </div>
        </div>
        <div class="footer-sales mt-5">
            <div class="d-flex justify-content-center">
                <div class="text-grey small-text">
                    Socri REIM | {{ ChannelModule.channel.name }}
                </div>
            </div>
        </div>

        {{ /* INPUTS MOBILE */ }}
        <div
                v-if="card.index !== null"
                class="card border-0 app-box-shadow p-2 sale-mobile position-fixed"
                :class="(card.bgColor !== null) ? card.bgColor : 'bg-green-dark'"
        >
            <div class="header-sale-mobile mb-4">
                <div class="d-flex justify-content-end">
                    <b-icon
                            v-on:click="closeBlockSaleMobile"
                            class=""
                            icon="x-square"
                            font-scale="1.5"
                            variant="danger"
                    ></b-icon>
                </div>

                <div class="text-center">
                    <div class="d-flex justify-content-center">
                        <div>
                            <b-icon
                                    v-on:click="
                    (card.index > 0) ? card.index -= 1 : card.index;
                    navBlockSaleMobile(card.index)
                  "
                                    class="button-period mr-3 p-2"
                                    icon="chevron-left"
                                    font-scale="2.5"
                                    :variant="(card.index > 0) ? 'light' : 'secondary'"
                            ></b-icon>
                        </div>
                        <div class="text-center mt-2 mr-4 ml-4 text-capitalize">
                            {{ card.date | moment('dddd DD MMMM YYYY') }}
                        </div>
                        <div>
                            <b-icon
                                    v-on:click="
                    (card.index < listByDay.length - 1) ? card.index += 1 : card.index;
                    navBlockSaleMobile(card.index)
                  "
                                    class="button-period mr-3 p-2"
                                    icon="chevron-right"
                                    font-scale="2.5"
                                    :variant="(card.index < listByDay.length - 1) ? 'light' : 'secondary'"
                            ></b-icon>
                        </div>
                    </div>
                </div>
            </div>

            <div class="inputs mt-3">
                <div class="input-group" v-if="checkUserCanEdit(card)">
                    <input
                            type="number"
                            step="0.01"
                            class="form-control border-0"
                            v-model="card.totalWithoutVat"
                            placeholder="CA"
                            v-on:keyup="onChangeInputWithoutVat($event, card)"
                    >
                    <input type="hidden" v-model="card.userWithoutVat">
                    <div class="input-group-append">
                        <div class="input-group-text text-grey bg-white border-0">&nbsp;HT</div>
                    </div>
                </div>
                <div class="d-flex justify-content-center" v-else>
                    <span class="text-light big-text">{{ (card.totalWithoutVat) ? card.totalWithoutVat : 0 }} €</span>
                    <span class="text-light ml-4">HT</span>
                </div>

                <div class="input-group mt-2" v-if="checkUserCanEdit(card)">
                    <input
                            type="number"
                            step="0.01"
                            class="form-control border-0"
                            v-model="card.totalWithVat"
                            placeholder="CA"
                            v-on:keyup="onChangeInputWithVat($event, card)"
                    >
                    <input type="hidden" v-model="card.userWithVat">
                    <div class="input-group-append">
                        <div class="input-group-text text-grey bg-white border-0">TTC</div>
                    </div>
                </div>
                <div v-else>
                    <div class="d-flex justify-content-center">
                        <span class="text-light big-text">{{ (card.totalWithVat) ? card.totalWithVat : 0 }} €</span>
                        <span class="text-light ml-4">TTC</span>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center mt-3 mb-3">
                <button
                        class="btn btn-outline-light"
                        v-if="checkUserCanEdit(card) && !errorSales"
                        v-on:click="submitSaleMobile"
                >
                    Valider
                </button>
                <div v-else-if="card.error">
                    <span class="text-danger">{{ card.error }}</span>
                </div>
            </div>
        </div>

        {{ /* Modal Ask For Help */ }}
        <div class="div-modal">
            <b-modal
                    :key="componentKey + 100"
                    id="modal-form-ask-for-help"
                    ref="modal"
                    title="Vous avez demandé de l'aide ?"
                    v-model="askForHelp.clicked"
                    @hidden="resetModal"
                    @ok="handleModalAskForHelp"
                    header-bg-variant="danger"
                    header-text-variant="light"
                    body-bg-variant="light"
                    footer-bg-variant="light"
            >
                <b-alert show variant="info">
                    Merci de renseigner votre téléphone OU votre e-mail,
                    <br/>
                    Nos administrateurs vous contacteront dans les plus brefs délais
                </b-alert>

                <form ref="form">
                    <div class="form-group">
                        <label for="ask-for-help-phone">Votre téléphone</label>
                        <input class="form-control" id="ask-for-help-phone" v-model="askForHelp.form.phone" type="tel"
                               placeholder="Votre téléphone">
                    </div>
                    <div class="form-group">
                        <label for="ask-for-help-email">Votre Email</label>
                        <input class="form-control" id="ask-for-help-email" v-model="askForHelp.form.email" type="email"
                               placeholder="Votre email">
                    </div>
                    <div class="form-group">
                        <label for="ask-for-help-email">Merci de laisser un commentaire</label>
                        <textarea class="form-control" id="ask-for-help-comment" v-model="askForHelp.form.comment"
                                  placeholder="Votre commentaire">

            </textarea>
                    </div>
                </form>

                <div v-if="askForHelp.form.error">
                    <span class="text-danger">{{ askForHelp.form.error }}</span>
                </div>

                <template #modal-footer="{ ok, cancel }">
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button size="sm" variant="secondary" @click="cancel()">
                        Annuler
                    </b-button>
                    <b-button size="sm" variant="success" @click="ok()">
                        Envoyez la demande d'aide
                    </b-button>
                </template>
            </b-modal>

            {{ /* Modal Fill Month */ }}
            <div class="div-modal">
                <b-modal
                        :key="componentKey + 102"
                        id="add-comment-modal"
                        ref="modal"
                        title="Vous avez rempli votre mois"
                        v-model="fillMonth.clicked"
                        @hidden="resetModalFillMonth"
                        @ok="handleModalFillMonth"
                        :hide-header-close="true"
                        :no-close-on-esc="true"
                        :no-close-on-backdrop="true"
                        header-bg-variant="danger"
                        header-text-variant="light"
                        body-bg-variant="light"
                        footer-bg-variant="light"
                >
                    <form ref="form">
                        <div class="form-group">
                            <label for="ask-for-help-email">Merci de laisser un commentaire</label>
                            <textarea class="form-control" id="fill_month-comment" v-model="fillMonth.form.comment"
                                      placeholder="Votre commentaire">
              </textarea>
                        </div>
                    </form>

                    <template #modal-footer="{ ok }">
                        <b-button size="sm" variant="success" @click="ok()">
                            Valider votre mois
                        </b-button>
                    </template>
                </b-modal>
            </div>
            <div class="div-modal">
                <b-modal
                        :key="componentKey + 104"
                        id="days-not-filled-modal"
                        ref="modal"
                        title="Attention"
                        v-model="notFilledDays"
                        @ok=close()
                        :hide-header-close="true"
                        :no-close-on-esc="true"
                        :no-close-on-backdrop="true"
                        header-bg-variant="danger"
                        header-text-variant="light"
                        body-bg-variant="light"
                        footer-bg-variant="light"
                >
                    Vous êtes en train de renseigner un CA pour un jour alors que un ou plusieurs jours antérieurs ne
                    sont pas remplis.
                    <b>N'oubliez pas de préciser 0 en CA pour les jours de fermeture.</b>
                    <template #modal-footer="{ ok }">
                        <b-button size="sm" variant="success" @click="ok()">
                            Ok
                        </b-button>
                    </template>
                </b-modal>
            </div>
        </div>
    </div>

</template>

<script>
import {mapState} from 'vuex'
import moment from 'moment'
import {fr} from 'vuejs-datepicker/dist/locale'

export default {
    data() {
        return {
            componentKey: 0,
            showHelpMobile: false,
            loader: {
                opacity: 0.9,
                isLoading: false,
                fullPage: true,
            },
            totalMonthWithVat: null,
            isOnMobile: false,
            currentInputValueWithVat: null,
            totalMonthWithoutVat: null,
            currentInputValueWithoutVat: null,
            userCanEdit: this.userCanEditComputed,
            errorFormSales: false,
            saleError: {
                isError: false,
                message: null
            },
            card: {
                id: null,
                totalWithVat: null,
                totalWithoutVat: null,
                userWithVat: null,
                userWithoutVat: null,
                vatRate: null,
                date: null,
                index: null,
                bgColor: null
            },
            datepicker: {
                language: fr,
                defaultDate: null,
                disabledDates: {
                    to: null, // Disable all dates up to specific date
                    from: new Date(new Date()), // Disable all dates after specific date
                },
            },
            page: {
                doubleEntryMessage:
                    'Attention votre magasin est en double saisie, merci de bien remplir les deux champs du formulaire pour chaque ligne.'
                ,
            },
            period: {
                month: null,
                monthStr: null,
                monthSubtract: 0,
                dateLimit: null,
                year: null
            },
            listByDay: [],
            errorSales: false,
            countFillCardSales: null,
            fillMonth: {
                clicked: false,
                form: {
                    comment: null
                }
            },
            saleModel: {
                id: null,
                date: null,
                totalWithVat: null,
                totalWithoutVat: null,
                userWithVat: null,
                userWithoutVat: null,
                vatRate: null,
                bgColor: null
            },
            askForHelp: {
                isEmailSend: false,
                clicked: false,
                form: {
                    phone: null,
                    email: null,
                    comment: null,
                    error: null
                }
            },
            initialDataHasLoaded: false,
            notFilledDays: false,
        }
    },
    created() {
        // Redirect if is not user sales
        if (Number(this.$route.params.id) !== Number(this.$store.getters.getCurrentUser.shop.id)) {
            this.$router.replace({name: 'sale', params: {id: this.$store.getters.getCurrentUser.shop.id}}, () => {
                this.$router.go(0);
            })
        } else {
            this.prepareDataPage()
        }

        this.userCanEdit = this.userCanEditComputed
    },
    methods: {
        /*----------------- LOADER ------------------*/
        showLoader() {
            this.loader.isLoading = true
        },
        hideLoader() {
            this.loader.isLoading = false
        },
        goToPreviousMonth() {
            let currentDate = this.getDatePeriod("YYYY-MM-DD")
            let newDate = moment(currentDate).subtract(1, 'months').format("YYYY-MM-DD");
            this.setNewDateInfos(newDate);
        },
        goToNextMonth() {
            let currentDate = this.getDatePeriod("YYYY-MM-DD")
            let newDate = moment(currentDate).add(1, 'months').format("YYYY-MM-DD");
            this.setNewDateInfos(newDate);
        },
        setNewDateInfos(newDate) {
            // Display loader
            this.showLoader()

            // Close active card
            this.card.index = null

            // Change period
            this.period.month = moment(newDate).format('M')
            this.period.monthStr = moment(newDate).format('MMMM')
            this.period.monthSubtract = 1
            this.period.year = moment(newDate).format('YYYY')
            this.period.dateLimit = this.getDateLimitPeriod(this.SaleModule.shop.dateLimitSale, 'YYYY-MM-DD')

            this.datepicker.defaultDate = moment(newDate).format('YYYY-MM-DD')

            // Fetch Sales
            this.$store.dispatch('fetchSales', {
                centerId: this.$store.getters.getChannel.center.id,
                month: this.period.month,
                year: this.period.year,
                shopId: this.$route.params.id
            })
                .then(() => {
                    // ListByDay
                    this.listByDay = this.getDaysInMonth(this.period.month, this.period.year)

                    // Sale model
                    this.saleModel = this.setModelSaleComputed
                    // Count list sales
                    this.countFillCardSales = this.$store.getters.getCountSales
                    // Total Month
                    this.totalMonthWithVat = this.$store.getters.getCountSalesWithVat
                    this.totalMonthWithoutVat = this.$store.getters.getCountSalesWithoutVat
                    this.period.dateLimit = this.getDateLimitPeriod(this.SaleModule.shop.dateLimitSale, 'YYYY-MM-DD')

                    // Hide loader
                    this.hideLoader()
                })
        },
        formatNumbersWithSpacePerThousands(number) {
            if (number) {
                let parts = number.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                return parts.join(".");
            }
            return null;
        },
        /*----------------------  BLOCK SALE MOBILE ----------------*/
        clickButtonHelpMobile() {
            this.showHelpMobile = !this.showHelpMobile
        },
        closeBlockHelpMobile() {
            this.showHelpMobile = false
        },
        /**
         * Toggle active on card
         *
         * @param index
         * @param sale
         */
        cardActiveEvent(index, sale) {
            // Set obj card
            this.card.id = (sale.id) ? sale.id : null
            this.card.index = index
            this.card.date = sale.date
            this.card.vatRate = (sale.vatRate) ? sale.vatRate : null
            this.card.totalWithoutVat = (sale.totalWithoutVat) ? sale.totalWithoutVat : null
            this.card.totalWithVat = (sale.totalWithVat) ? sale.totalWithVat : null
            this.card.userWithoutVat = (sale.userWithoutVat) ? sale.userWithoutVat : null
            this.card.userWithVat = (sale.userWithVat) ? sale.userWithVat : null
            this.card.bgColor = (sale.bgColor) ? sale.bgColor : 'bg-green-dark'
        },
        /**
         * Nav between card
         */
        navBlockSaleMobile(index) {
            this.card.index = index
            this.card.date = this.listByDay[index].date
            this.card.vatRate = (this.listByDay[index].vatRate) ? this.listByDay[index].vatRate : null
            this.card.totalWithoutVat = (this.listByDay[index].totalWithoutVat) ? this.listByDay[index].totalWithoutVat : null
            this.card.totalWithVat = (this.listByDay[index].totalWithVat) ? this.listByDay[index].totalWithVat : null
            this.card.userWithoutVat = (this.listByDay[index].userWithoutVat) ? this.listByDay[index].userWithoutVat : null
            this.card.userWithVat = (this.listByDay[index].userWithVat) ? this.listByDay[index].userWithVat : null
            this.card.bgColor = (this.listByDay[index].bgColor) ? this.listByDay[index].bgColor : 'bg-green-dark'
        },
        /**
         * Close block sale mobile
         */
        closeBlockSaleMobile() {
            this.card = {
                totalWithVat: null,
                totalWithoutVat: null,
                userWithVat: null,
                userWithoutVat: null,
                date: null,
                index: null,
                bgColor: null
            }
        },
        submitSaleMobile() {
            this.isOnMobile = true;
            if (!this.checkIfAllDaysAreFilled(true)) {
                this.$swal({
                    title: 'Validation de votre saisie chiffre d\'affaires',
                    text: "Valider votre / vos saisie(s)  ?",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#2FA76E',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Oui',
                    cancelButtonText: 'Non'
                }).then((result) => {
                    if (result.value) {
                        this.initSubmitMobile()
                    }
                })
            } else {
                this.$swal({
                    title: 'Validation du mois en cours',
                    text: "Attention: Vous ne pourrez plus éditer vos saisies, Si vous validez, un mail sera envoyé au centre",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#2FA76E',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Oui',
                    cancelButtonText: 'Non'
                }).then((result) => {
                    if (result.value) {
                        this.fillMonth.clicked = true
                    }
                })
            }
        },
        initSubmitMobile() {
            // Send create
            this.$store.dispatch('createOrUpdateSales', {
                centerId: this.$store.getters.getChannel.center.id,
                data: [this.card],
                shopId: this.SaleModule.shop.id,
                userId: this.AuthModule.currentUser.id,
                comment: this.fillMonth.form.comment ?? null
            })
                .then(() => {
                    // Fetch Sales
                    this.$store.dispatch('fetchSales', {
                        centerId: this.$store.getters.getChannel.center.id,
                        month: this.period.month,
                        year: this.period.year,
                        shopId: this.$route.params.id
                    })
                        .then(() => {
                            // ListByDay
                            this.listByDay = this.getDaysInMonth(this.period.month, this.period.year)

                            // Increment month total
                            this.incrementTotalMonthWithVat(this.card.totalWithVat)
                            this.incrementTotalMonthWithoutVat(this.card.totalWithoutVat)

                            // Hide loader
                            this.hideLoader()
                        })
                })
        },
        /*----------------- GET DATA FROM API ------------------*/
        prepareDataPage() {
            // Display loader
            this.showLoader()

            // Key component
            this.componentKey += 1

            // Set period
            this.period.month = this.handleDate(0, 'MM')
            this.period.year = this.handleDate(0, 'YYYY')
            this.period.dateLimit = this.getDateLimitPeriod(this.SaleModule.shop.dateLimitSale, 'YYYY-MM-DD')

            // Fetch Shop
            let model = {
                centerId: this.$store.getters.getChannel.center.id,
                shopId: this.$route.params.id
            }

            this.$store.dispatch('fetchSalesShop', model).then(() => {

                // Fetch Sales for previous month
                this.$store.dispatch('fetchSales', {
                    centerId: this.$store.getters.getChannel.center.id,
                    month: this.handleDate(1, 'MM'),
                    year: this.handleDate(1, 'YYYY'),
                    shopId: this.$route.params.id
                })
                    .then(() => {
                        // set default date for datepicker
                        let dateObj = new Date(this.handleDate(1, 'YYYY-MM-DD'))
                        this.datepicker.defaultDate = moment(dateObj).format('YYYY-MM-DD')

                        let getLastDayInPreviousMonth = this.handleDate(1, 'YYYY-MM-DD')

                        if (this.SaleModule.shop.isMonth) {
                            const disableFromDate = moment().subtract(1, 'months')
                            this.datepicker.disabledDates.from = new Date(disableFromDate);
                        }

                        this.period.dateLimit = this.getDateLimitPeriod(this.SaleModule.shop.dateLimitSale, 'YYYY-MM-DD')

                        if (this.$store.getters.getCountSales !== moment(getLastDayInPreviousMonth).daysInMonth()) {
                            this.changePeriodSales(
                                Number(this.handleDate(1, 'MM')),
                                this.handleDate(1, 'MMMM'),
                                0,
                                Number(this.handleDate(1, 'YYYY'))
                            )

                            // ListByDay
                            this.listByDay = this.getDaysInMonth(this.period.month, this.period.year)

                            // Count list sales
                            this.countFillCardSales = this.$store.getters.getCountSales

                            // Sale model
                            this.saleModel = this.setModelSaleComputed

                            // Total Month
                            this.totalMonthWithVat = this.$store.getters.getCountSalesWithVat
                            this.totalMonthWithoutVat = this.$store.getters.getCountSalesWithoutVat

                        } else {
                            this.fetchSalesAndSetVar()
                        }

                        // Hide loader
                        this.hideLoader()
                        this.initialDataHasLoaded = true
                    })
            })

        },
        fetchSalesAndSetVar() {
            this.$store.dispatch('fetchSales', {
                centerId: this.$store.getters.getChannel.center.id,
                month: this.period.month,
                year: this.period.year,
                shopId: this.$route.params.id
            })
                .then(() => {
                    // set default date for datepicker
                    let dateObj = new Date(this.handleDate(0, 'YYYY-MM-DD'))
                    this.datepicker.defaultDate = moment(dateObj).format('YYYY-MM-DD')

                    // ListByDay
                    this.listByDay = this.getDaysInMonth(this.period.month, this.period.year)

                    // Count list sales
                    this.countFillCardSales = this.$store.getters.getCountSales

                    // Sale model
                    this.saleModel = this.setModelSaleComputed

                    // Total Month
                    this.totalMonthWithVat = this.$store.getters.getCountSalesWithVat
                    this.totalMonthWithoutVat = this.$store.getters.getCountSalesWithoutVat

                    // Hide loader
                    this.hideLoader()
                })
        },
        /*----------------- DATE ------------------*/
        /**
         * Format date for datepicker
         */
        formatDatepicker(date) {
            let dateObj = new Date(date)
            return this.capitalizeText(moment(dateObj).format('MMMM yyyy'));
        },
        /**
         * Get date period
         *
         * @param format
         */
        getDatePeriod(format) {
            let dateObj = new Date(this.period.year + '-' + this.period.month + '-01')
            return moment(dateObj).format(format)
        },
        handleDate($monthToSubtract, format) {
            let dateObj = new Date()
            let currentDate = moment(dateObj).format('YYYY-MM-DD')
            let newDate = moment(currentDate).subtract($monthToSubtract, 'month')

            return newDate.format(format)
        },
        getDateLimitPeriod(dateLimitSale, format) {
            let constructDateLimitDay = (dateLimitSale < 10) ? '0' + dateLimitSale : dateLimitSale
            let constructDate = this.period.year + '-' + this.period.month + '-' + constructDateLimitDay

            let dateObj = new Date(String(constructDate))
            let date = moment(dateObj)
            let newDate = moment(date).add(1, 'month')

            return newDate.format(format)
        },
        /**
         * List days of current month
         *
         * @returns {[]}
         */
        getDaysInMonth(month, year) {
            // current date for late sale
            let dateObj = new Date()
            let currentDate = moment(dateObj).format('YYYY-MM-DD')

            // init var
            let dayArray = []
            let strDate = new Date(year + '-' + month + '-01')

            // Set start and end date
            let startDayMonth = moment(strDate).format('YYYY-MM-DD')
            let endDayMonth = moment(strDate).format('YYYY-MM-') + moment(strDate).daysInMonth()

            // For loop between start and end day
            while (startDayMonth <= endDayMonth) {
                // Find sale by date
                let findSales = this.SaleModule.sales.find(item => item.date === startDayMonth)

                let model = {
                    date: startDayMonth,
                    id: (findSales !== undefined) ? findSales.id : null,
                    totalWithVat: (findSales !== undefined) ? findSales.totalWithVat : null,
                    totalWithoutVat: (findSales !== undefined) ? findSales.totalWithoutVat : null,
                    userWithVat: (findSales !== undefined) ? (findSales.userWithVat) ? findSales.userWithVat.id : null : null,
                    userWithoutVat: (findSales !== undefined) ? (findSales.userWithoutVat) ? findSales.userWithoutVat.id : null : null,
                    vatRate: (findSales !== undefined) ? findSales.vatRate : null,
                    bgColor: (findSales !== undefined) ? this.handleColorInput(findSales, true) : (currentDate > this.period.dateLimit) ? 'bg-danger' : '',
                    error: null
                }

                dayArray.push(model)
                startDayMonth = moment(startDayMonth).add(1, 'days').format('YYYY-MM-DD')
            }

            return dayArray
        },
        /**
         * Handle color input
         *
         * @param sale
         * @param isVat
         */
        handleColorInput(sale, isVat) {
            // current date for late sale
            let currentDate = moment().format('YYYY-MM-DD')

            if (sale !== undefined) {
                if (isVat) {
                    if (sale.userWithVat != null) {
                        // IsMonth or daily
                        if (sale.userWithVat instanceof Object) {
                            if (sale.userWithVat.id === this.AuthModule.currentUser.id) {
                                return 'bg-primary'
                            } else {
                                return 'bg-warning'
                            }
                        } else {
                            if (sale.userWithVat === this.AuthModule.currentUser.id) {
                                return 'bg-primary'
                            } else {
                                return 'bg-warning'
                            }
                        }
                    } else {
                        if (currentDate > this.period.dateLimit) {
                            return 'bg-danger'
                        } else {
                            return ''
                        }
                    }
                } else {
                    if (sale.userWithoutVat != null) {
                        if (sale.userWithoutVat instanceof Object) {
                            if (sale.userWithoutVat.id === this.AuthModule.currentUser.id) {
                                return 'bg-primary'
                            } else {
                                return 'bg-warning'
                            }
                        } else {
                            if (sale.userWithoutVat === this.AuthModule.currentUser.id) {
                                return 'bg-primary'
                            } else {
                                return 'bg-warning'
                            }
                        }
                    } else {
                        if (currentDate > this.period.dateLimit) {
                            return 'bg-danger'
                        } else {
                            return ''
                        }
                    }
                }
            }
        },
        /*----------------- Change period ------------------*/
        changePeriodSales($month, $monthStr, $monthSubtract, $year) {
            // Display loader
            this.showLoader()

            // Close active card
            this.card.index = null

            // Change period
            this.period.month = ($month < 10) ? '0' + $month : $month
            this.period.monthStr = $monthStr
            this.period.monthSubtract = $monthSubtract
            this.period.year = $year
            this.period.dateLimit = this.getDateLimitPeriod(this.SaleModule.shop.dateLimitSale, 'YYYY-MM-DD')

            // Fetch Sales
            this.$store.dispatch('fetchSales', {
                centerId: this.$store.getters.getChannel.center.id,
                month: this.period.month,
                year: this.period.year,
                shopId: this.$route.params.id
            })
                .then(() => {
                    // ListByDay
                    this.listByDay = this.getDaysInMonth(this.period.month, this.period.year)

                    // Sale model
                    this.saleModel = this.setModelSaleComputed
                    // Count list sales
                    this.countFillCardSales = this.$store.getters.getCountSales
                    // Total Month
                    this.totalMonthWithVat = this.$store.getters.getCountSalesWithVat
                    this.totalMonthWithoutVat = this.$store.getters.getCountSalesWithoutVat

                    // Hide loader
                    this.hideLoader()
                })
        },
        /*----------------- On change input ------------------*/
        onChangeInputWithVat($event, sale) {
            if ($event.target.value) {
                if (!this.SaleModule.shop.doubleSaleEntry) {
                    // Refresh totalWithoutVat
                    sale.totalWithoutVat = null
                    // AutoComplete field totalWithoutVat
                    sale.totalWithoutVat = this.calculationSale('withoutVat', sale.totalWithVat)

                    if (!this.allPreviousDaysAreFilled(this.listByDay, this.listByDay.indexOf(sale))) {
                        this.notFilledDays = true;
                    }

                    // total month
                    this.onFocusInputWithoutVat()
                }

                // Set user without vat
                sale.userWithoutVat = this.AuthModule.currentUser.id
                // Color input
                sale.bgColor = 'bg-info'
                // Set user
                sale.userWithVat = this.AuthModule.currentUser.id
                // handle error
                this.handleErrorSale($event, sale)
            }
        },
        onChangeInputWithoutVat($event, sale) {
            if ($event.target.value) {
                if (!this.SaleModule.shop.doubleSaleEntry) {
                    // Refresh totalWithVat
                    sale.totalWithVat = null
                    // AutoComplete field totalWithVat
                    sale.totalWithVat = this.calculationSale('withVat', sale.totalWithoutVat)

                    if (!this.allPreviousDaysAreFilled(this.listByDay, this.listByDay.indexOf(sale))) {
                        this.notFilledDays = true;
                    }

                    // total month
                    this.onFocusInputWithVat()
                }

                // Set user without vat
                sale.userWithVat = this.AuthModule.currentUser.id
                // Color input
                sale.bgColor = 'bg-info'
                // Set user
                sale.userWithoutVat = this.AuthModule.currentUser.id
                // handle error
                this.handleErrorSale($event, sale)
            }
        },
        onFocusInputWithVat() {
            this.totalMonthWithVat = this.listByDay.reduce((result, sale) => {
                result += Number(sale.totalWithVat)
                return Math.round(result)
            }, 0)
        },
        onFocusInputWithoutVat() {
            this.totalMonthWithoutVat = this.listByDay.reduce((result, sale) => {
                result += Number(sale.totalWithoutVat)

                return Math.round(result)
            }, 0)
        },
        incrementTotalMonthWithVat() {
            this.onFocusInputWithVat()
        },
        incrementTotalMonthWithoutVat() {
            this.onFocusInputWithoutVat()
        },
        handleErrorSale($event, sale) {
            if (this.SaleModule.shop.doubleSaleEntry) {
                if (sale.totalWithoutVat && sale.totalWithVat) {
                    // Calculation vat rate
                    this.checkVatRatBetweenSales(sale)
                } else {
                    sale.error = 'Merci de remplir les deux champs'
                    this.errorSales = true
                }
            } else {
                if (sale.totalWithoutVat || sale.totalWithVat) {
                    // Calculation vat rate
                    this.checkVatRatBetweenSales(sale)
                } else {
                    sale.error = 'Merci de remplir les deux champs'
                    this.errorSales = true
                }
            }
        },
        incrementCountFillCardSales(sale) {
            if (sale.totalWithVat && sale.totalWithoutVat) {
                this.countFillCardSales++
            }
        },
        /*----------------- CalculationVatRate ------------------*/
        checkVatRatBetweenSales(sale) {
            let subtractPercentage = (Math.abs(sale.totalWithVat) / 4) * 3

            if (Math.abs(sale.totalWithoutVat) < subtractPercentage) {
                this.errorSales = true
                sale.error = 'Merci de vérifier votre saisie, pourcentage TVA trop élevé !'
            } else {
                this.errorSales = false
                sale.error = ''
            }
        },
        /**
         * Calculation vatRate
         */
        calculationSale(saleType, total) {
            let vatPercentage = (this.SaleModule.shop.vatRate / 100) + 1

            if (saleType === 'withVat') {
                let calculationSaleWithVat = Number(total) * Number(vatPercentage)
                return Math.round((calculationSaleWithVat + Number.EPSILON) * 100) / 100
            } else {
                let calculationSaleWithoutVat = Number(total) / Number(vatPercentage)
                return Math.round((calculationSaleWithoutVat + Number.EPSILON) * 100) / 100
            }
        },
        /*----------------- DATEPICKER -------------*/
        /**
         * Event click datePicker date
         *
         * @param value
         */
        clickDatePicker(value) {
            // Pass str to date Obj
            const dateObj = new Date(value)
            let newDate = moment(dateObj)

            this.setNewDateInfos(newDate)
        },
        /*----------------- Submit form ------------------*/
        submitForm(isMonth) {
            if (isMonth) {
                this.$swal({
                    title: 'Validation du mois en cours',
                    text: "Attention: Vous ne pourrez plus éditer vos saisies, Si vous validez, un mail sera envoyé au centre",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#2FA76E',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Oui',
                    cancelButtonText: 'Non'
                }).then((result) => {
                    if (result.value) {
                        this.fillMonth.clicked = true
                    }
                })
            } else {
                if (!this.checkIfAllDaysAreFilled(false)) {
                    this.$swal({
                        title: 'Validation de votre saisie chiffre d\'affaires',
                        text: "Valider votre / vos saisie(s)  ?",
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#2FA76E',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Oui',
                        cancelButtonText: 'Non'
                    }).then((result) => {
                        if (result.value) {
                            this.initSubmit(isMonth)
                        }
                    })
                } else {
                    this.$swal({
                        title: 'Validation du mois en cours',
                        text: "Attention: Vous ne pourrez plus éditer vos saisies, Si vous validez, un mail sera envoyé au centre",
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#2FA76E',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Oui',
                        cancelButtonText: 'Non'
                    }).then((result) => {
                        if (result.value) {
                            this.fillMonth.clicked = true
                        }
                    })
                }
            }
        },
        checkIfDayIsFilled(day) {
            if (parseInt(day.totalWithVat) === 0 && parseInt(day.totalWithoutVat) === 0) {
                return true;
            }
            if ((day.totalWithVat && day.totalWithVat != '') && (day.totalWithoutVat && day.totalWithoutVat != '')) {
                return true
            }
            return false
        },
        checkIfAllDaysAreFilled(isOnMobile = false) {

            const daysNotFilled = this.listByDay.filter((day) => {
                return !this.checkIfDayIsFilled(day)
            })

            if (isOnMobile && daysNotFilled.length <= 1) {
                return true;
            } else {
                return !(daysNotFilled && daysNotFilled.length > 0);
            }
        },
        capitalizeText(initialText) {
            return initialText.charAt(0).toUpperCase() + initialText.slice(1);
        },
        initSubmit(isMonth) {
            // Display loader
            this.showLoader()

            if (isMonth) {
                if (this.SaleModule.shop.doubleSaleEntry && (!this.saleModel.totalWithoutVat || !this.saleModel.totalWithVat)) {
                    this.loader.isLoading = false
                    this.errorFormSales = 'Merci de remplir les deux champs'
                } else {
                    this.errorFormSales = false

                    // Send create
                    this.$store.dispatch('createOrUpdateSales', {
                        centerId: this.$store.getters.getChannel.center.id,
                        data: [this.saleModel],
                        shopId: this.SaleModule.shop.id,
                        userId: this.AuthModule.currentUser.id,
                        comment: this.fillMonth.form.comment
                    })
                        .then(() => {
                            // Fetch Sales
                            this.$store.dispatch('fetchSales', {
                                centerId: this.$store.getters.getChannel.center.id,
                                month: this.period.month,
                                year: this.period.year,
                                shopId: this.$route.params.id
                            })
                                .then(() => {
                                    // Set sale model
                                    this.saleModel = this.setModelSaleComputed

                                    // Hide loader
                                    this.hideLoader()
                                })
                        })
                }
            } else {
                // Handle list by day for create
                let listForCreate = []
                this.fillMonth.clicked = false

                this.listByDay.forEach((sale) => {
                    if (sale.userWithVat !== null || sale.userWithoutVat !== null) {
                        listForCreate.push(sale)
                    }
                })

                // Send create
                this.$store.dispatch('createOrUpdateSales', {
                    centerId: this.$store.getters.getChannel.center.id,
                    data: listForCreate,
                    shopId: this.SaleModule.shop.id,
                    userId: this.AuthModule.currentUser.id,
                    comment: this.fillMonth.form.comment
                })
                    .then(() => {
                        this.saleError.isError = false
                        this.saleError.errorMessage = null

                        // Fetch Sales
                        this.$store.dispatch('fetchSales', {
                            centerId: this.$store.getters.getChannel.center.id,
                            month: this.period.month,
                            year: this.period.year,
                            shopId: this.$route.params.id
                        })
                            .then(() => {
                                // ListByDay
                                this.listByDay = this.getDaysInMonth(this.period.month, this.period.year)

                                // Hide loader
                                this.hideLoader()
                            })
                    })
                    .catch((error) => {
                        this.saleError.isError = true
                        this.saleError.errorMessage = error

                        this.hideLoader()
                    })
            }
        },
        /*--------------- MAIL ----------------*/
        /**
         * Send email on click ask for help
         */
        sendEmailAskForHelp() {
            this.$store.dispatch('sendEmailAskForHelp', {
                centerId: this.$store.getters.getChannel.center.id,
                shopId: this.$route.params.id,
                userId: this.AuthModule.currentUser.id,
                phone: this.askForHelp.form.phone,
                comment: this.askForHelp.form.comment,
                email: this.askForHelp.form.email
            })
                .then(() => {
                    this.askForHelp.isEmailSend = true
                    this.askForHelp.clicked = false

                    // Hide the modal manually
                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-form-ask-for-help')
                    })
                })
        },
        /**
         * Reset for ask for help
         */
        resetEmailSend() {
            this.askForHelp.isEmailSend = false
            this.askForHelp.form = {
                phone: null,
                email: null,
                error: null
            }
        },
        /*--------------- MODAL ----------------*/
        /**
         * Reset modal data
         */
        resetModal() {
            this.askForHelp.clicked = false
        },
        /**
         * Click on send modal ask for help
         */
        handleModalAskForHelp(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()

            // Send email
            if (this.askForHelp.form.phone === null && this.askForHelp.form.email === null) {
                this.askForHelp.form.error = 'Merci de renseigner au moins le téléphone ou l\'e-mail'
            } else {
                this.sendEmailAskForHelp()
                this.askForHelp.form.error = null
            }
        },
        /*--------------- MODAL ----------------*/
        /**
         * Reset modal data
         */
        resetModalFillMonth() {
            this.fillMonth.clicked = false
            this.fillMonth.form.comment = null
        },
        /**
         * Click on send modal fill Month
         */
        handleModalFillMonth(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()

            // Validate Month
            if (!this.isOnMobile) {
                this.initSubmit(this.SaleModule.shop.isMonth)
            } else {
                this.initSubmitMobile()
            }
            this.$nextTick(() => {
                this.$bvModal.hide('add-comment-modal')
            })
        },
        /*--------------- USER CAN EDIT ----------------*/
        checkUserCanEdit(elem) {
            let isEdit = false
            if (elem.bgColor === 'bg-warning') {
                isEdit = false
            } else {
                if (this.SaleModule.shop.isMonth) {
                    isEdit = (this.SaleModule.sales.length === 0)
                } else {
                    isEdit = this.getLastDayInMonthPeriod !== this.$store.getters.getCountSales
                }
            }

            return isEdit
        },
        allPreviousDaysAreFilled(days, index) {
            let filled = true;
            days.forEach((day) => {
                if (days.indexOf(day) < index && (day.totalWithoutVat === null || day.totalWithVat === null)) {
                    filled = false;
                }
            })
            return filled;
        },
    },
    watch: {
        setModelSaleComputed: function (newValue) {
            this.saleModel = newValue
        },
    },
    computed: {
        getLastDayInMonthPeriod() {
            let constructDate = this.period.year + '-' + this.period.month + '-01'
            let dateObj = new Date(constructDate)
            let date = moment(dateObj).format("YYYY-MM-DD")

            return parseInt(moment(date).daysInMonth())
        },
        isNextMonthDisabled() {
            let constructDate = this.period.year + '-' + this.period.month + '-01'
            let dateObj = new Date(constructDate)
            let nextMonthDate = moment(dateObj).add(1, 'months').format("YYYY-MM-DD")
            const currentDate = moment();

            // If shop is month, the check is on last month as the month has to be finished to enable the user to enter his sales
            if (this.SaleModule.shop.isMonth) {
                const dateToCompare = moment(currentDate).subtract(1, 'months').format("YYYY-MM-DD");
                if (moment(nextMonthDate).isAfter(dateToCompare)) {
                    return true
                }
            } else {
                if (moment(nextMonthDate).isAfter(currentDate)) {
                    return true
                }
            }
            return false
        },
        /**
         * set computed sale model
         * @returns {{date: (*|null), userWithoutVat: (null|*|null), totalWithVat: (null|*|null), vatRate: (null|*|null), id: (*|null), totalWithoutVat: (null|*|null), userWithVat: (null|*|null)}}
         */
        setModelSaleComputed() {
            // current date for late sale
            let dateObj = new Date()
            let currentDate = moment(dateObj).format('YYYY-MM-DD')

            return {
                id: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].id : null,
                date: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].date : this.getDatePeriod('YYYY-MM-DD'),
                totalWithVat: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].totalWithVat : null,
                totalWithoutVat: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].totalWithoutVat : null,
                userWithVat: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].userWithVat.id : null,
                userWithoutVat: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].userWithoutVat.id : null,
                vatRate: (this.SaleModule.sales[0]) ? this.SaleModule.sales[0].vatRate : null,
                bgColor: (this.SaleModule.sales[0]) ? this.handleColorInput(this.SaleModule.sales[0], true) : (currentDate > this.period.dateLimit) ? 'bg-danger' : '',
            }
        },
        userCanEditComputed() {
            return false
        },
        upperCaseMonthStr() {
            if (this.period.monthStr) {
                return this.period.monthStr.toUpperCase() + ' ' + this.period.year
            } else {
                return ''
            }
        },
        ...mapState(['ChannelModule']),
        ...mapState(['AuthModule']),
        ...mapState(['SaleModule']),
    },
    components: {
        Datepicker: () => import('vuejs-datepicker'),
    },
}
</script>
