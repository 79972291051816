import PublicService from '@/services/PublicService'
import UserService from '@/services/UserService'

export default {
  state: {
    tempUser: {},
  },
  mutations: {
    SET_TEMP_USER(state, user) {
      state.tempUser = user
    }
  },
  actions: {
    /**
     * Get public user
     *
     * @param commit
     * @param token
     * @param id
     * @returns {*}
     */
    getPublicUser({ commit }, {token, id})
    {
      return PublicService.getPublicUser(token, id)
        .then((response) => {

          if(response.data.error_wrong_user || response.data.error_token)
          {
            return Promise.reject(response.data)
          }

          commit('SET_TEMP_USER', response.data.user)
        })
    },
      fetchUser({commit}, userId)
      {
          // Call axios (user)
          return UserService
              .getUser(userId)
              .then((response) => {
                  commit('SET_CURRENT_USER', response.data)
              })
              .catch((error) => {
                  return Promise.reject(error)
              })
      },
    /**
     * Update user
     *
     * @param commit
     * @param model
     * @returns {*}
     */
    updateUser({ commit }, model)
    {
      return UserService.edit(model)
        .then((response) => {
          if(response.data.errors)
          {
            return Promise.reject(response.data.errors)
          }

          commit('SET_CURRENT_USER', response.data)
        })
    },
    /**
     * Update password
     *
     * @param commit
     * @param token
     * @param id
     * @param data
     * @returns {*}
     */
    updatePassword({ commit }, {token, id, data})
    {
      return PublicService.updatePassword(token, id, data)
        .then((response) => {
          if(response.data.errors) {
              return Promise.reject(response.data.errors)
          }
          if(response.data.error_token || response.data.error_wrong_user)
          {
              return Promise.resolve(response.data)
          }

          commit('SET_TEMP_USER', response.data)
        })
    }
  },
  getters: {
  }
}
